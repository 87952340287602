// You can choose any icon from this list: https://react-icons.github.io/react-icons
import {
  FaSkullCrossbones,
  FaAngleDown,
  FaRegCalendarAlt,
  FaCode,
  FaCommentDots,
  FaDiscord,
  FaEnvelope,
  FaFacebook,
  FaGithub,
  FaGitlab,
  FaHackerrank,
  FaInstagram,
  FaLinkedin,
  FaSteam,
  FaWhatsapp
} from 'react-icons/fa';

import {
  SiHackerone,
  SiHackthebox,
  SiPastebin,
  SiTryhackme
} from "react-icons/si";

// This is the color list you can use for now: https://mui.com/material-ui/customization/color/
import {
  amber,
  red,
  grey
} from '@mui/material/colors';

import Youtube from './components/Youtube'

// @TODO: Create colorset for light and dark modes.
// @TODO: Properly use button and icon colors or maybe even ditch out
const colors = {
  "mode": "dark",
  "primary": red,
  "secondary": amber,
  "button": red,
  "icon": red
}

const routes = {
  "blog": "https://devjourney.osouza.de/blog",
  "blog-pt": "https://devjourney.osouza.de/pt/blog",
  "calendly": "https://calendly.com/osouza-de",
  "devjourney": "https://devjourney.osouza.de/docs",
  "discord": "https://discord.gg/nF922t8",
  "email": "mailto:website@osouza.de?subject=Website%20contact",
  "facebook": "https://facebook.com/profile.php",
  "github": "https://github.com/osouza-de",
  "gitlab": "https://gitlab.com/osouza.de",
  "hackerone": "https://hackerone.com/osouza-de",
  "hackerrank": "https://www.hackerrank.com/osouza_de",
  "hackthebox": "https://app.hackthebox.com/profile/200117",
  "instagram": "https://instagram.com/osouza.de",
  "linkedin": "https://www.linkedin.com/in/osouza-de",
  "mentebinaria": "https://www.mentebinaria.com.br/profile/30-d4nd/",
  "microsoft": "https://docs.microsoft.com/en-us/users/osouzade",
  "steam": "https://steamcommunity.com/profiles/76561193889873186",
  "tryhackme": "https://tryhackme.com/p/osouza.de",
  "uh": "http://159.223.102.122/users/36",
  "website": "https://osouza.de",
  "whatsapp": "https://api.whatsapp.com/send?phone=555492746917&text=Website%20contact",
  "www": "https://youtu.be/5QdL09Q4wLI?si=TwCCWkH3CEHDvDIj"
}

const icons = [
  {
    "tooltip": "@osouza.de | My pics's place",
    "icon": <FaInstagram />,
    "route": "instagram"
  },
  {
    "tooltip": "Check out my Linkedin profile.",
    "icon": <FaLinkedin />,
    "route": "linkedin",
    "hidden": true
  },
  {
    "tooltip": "Send me a e-mail!",
    "icon": <FaEnvelope />,
    "route": "email",
    "hidden": false
  },
  {
    "tooltip": "My Discord server.",
    "icon": <FaDiscord />,
    "route": "discord",
  },
  {
    "tooltip": "xxx",
    "icon": <FaSkullCrossbones />,
    "route": "www",
    "color": grey[900]
  }
]

// @TODO: maybe create a grouping option?

const items = [
  {
    "title": "//whoami",
    "content": "Brazilian, 29, Ethical Hacker.<p>I've been fascinated by computers since I was a child. As a result, I now enjoy improving things with the assistance of I.T.</p><br/><small style='font-style:italic; float: right'>Zu sein und zu dauern.</small><br/>",
    "icon": <FaAngleDown />,
    "type": "accordion",
    "expanded": true
  },
  {
    "title": "LinkedIn",
    "content": "My LinkedIn!",
    "icon": <FaLinkedin />,
    "route": "linkedin",
    "type": "link",
    "hidden": true
  },
  {
    "title": "Dev Journey",
    "content": "My journey into the Development World!",
    "icon": <FaCode />,
    "route": "devjourney",
    "type": "link",
  },
  {
    "title": "Blog",
    "content": "My personal blog!",
    "icon": <FaCommentDots />,
    "route": "blog",
    "type": "link",
  },
  {
    "title": "GitLab",
    "content": "@osouza.de | My code house",
    "icon": <FaGitlab />,
    "route": "gitlab",
    "type": "link",
  },
  {
    "title": "GitHub",
    "content": "@osouza-de | (only for corporative projects...)",
    "icon": <FaGithub />,
    "route": "github",
    "type": "link",
  },
  {
    "title": "Mente Binária",
    "content": "My Mente Binaria's profile",
    "icon": <SiPastebin />,
    "route": "mentebinaria",
    "type": "link",
    "hidden": true
  },
  {
    "title": "HackerOne",
    "content": "My h1 profile page.",
    "icon": <SiHackerone />,
    "route": "hackerone",
    "type": "link",
    "hidden": true
  },
  {
    "title": "HackTheBox",
    "content": "My HTB profile page.",
    "icon": <SiHackthebox />,
    "route": "hackthebox",
    "type": "link",
  },
  {
    "title": "TryHackMe",
    "content": "My THM profile page.",
    "icon": <SiTryhackme />,
    "route": "tryhackme",
    "type": "link",
    "hidden": true
  }
]

const config = {
  "avatar": "https://avatars.githubusercontent.com/u/7597013?v=4",
  "title": "Anderson de Souza",
  colors,
  icons,
  items,
  routes
}

export default config
